
.menu-item {
    display: flex;
    flex-direction: row;
    width: '100%';
    padding: 1.2em;
    align-items: center;
}
.menu-item-dashboard {
    display: flex;
    flex-direction: row;
    width: '100%';
    padding: 1.2em;
    align-items: center;
}


.selectMenuItem {
    color: #006BD3;
}

.unSelectMenuItem {
    color: #000
}

 .menu-item:hover {
    background-color: #ace4ff;
    cursor: pointer;
}

.icon {
    margin-right: 1em;
}

.icon:hover {
    color: #fff
}

.left-box {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
}

.admin-name {
    color: #fff;
}