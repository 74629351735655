
.flex-container {
    display: flex;
    height: 100vh;
  }
  

.image-background {
    display: flex;
    background-color: var(--main-bg-color);
    justify-content: center;
    align-items: center;
  }


.login-display-image {
    height: 600px;
    width: 674px;
    margin-right: -180px;
    margin-left: 150px;
  }


.login-text-container {
    display: flex;
    width: 100%;
    z-index: 100;
    background-color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* text-align: center; */
  }

  .login-heading{
    font-size: 45px;
  }


.main-shade {
    color: var(--main-shade);
  }
  