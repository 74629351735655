@font-face {
    font-family: 'parkao';
    src:  url('static/fonts/parkao.eot?sqdcx9');
    src:  url('static/fonts/parkao.eot?sqdcx9#iefix') format('embedded-opentype'),
      url('static/fonts/parkao.ttf?sqdcx9') format('truetype'),
      url('static/fonts/parkao.woff?sqdcx9') format('woff'),
      url('static/fonts/parkao.svg?sqdcx9#parkao') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  .parkao-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'parkao' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 24px;
    color: #666;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-car6:before {
    content: "\e903";
  }
  .icon-car1:before {
    content: "\e904";
  }
  .icon-car:before {
    content: "\e905";
  }
  .icon-XL:before {
    content: "\e906";
  }
  .icon-car5:before {
    content: "\e907";
  }
  .icon-L:before {
    content: "\e908";
  }
  .icon-S:before {
    content: "\e909";
  }
  .icon-car2:before {
    content: "\e90a";
  }
  .icon-M:before {
    content: "\e90b";
  }
  .icon-bx-info-circle:before {
    content: "\e90c";
  }
  .icon-elevator:before {
    content: "\e90d";
  }
  .icon-high-voltage:before {
    content: "\e90e";
    color: #006bd3;
  }
  .icon-lock-1:before {
    content: "\e90f";
    color: #006bd3;
  }
  .icon-roadster:before {
    content: "\e910";
    color: #006bd3;
  }
  .icon-md-close:before {
    content: "\e911";
  }
  .icon-car-3:before {
    content: "\e912";
    color: #006bd3;
  }
  .icon-bicycle:before {
    content: "\e913";
    color: #006bd3;
  }
  .icon-24:before {
    content: "\e914";
    color: #006bd3;
  }
  .icon-space-bar-24px:before {
    content: "\e915";
    color: #006bd3;
  }
  .icon-arrow-right-top:before {
    content: "\e916";
  }
  .icon-wifi:before {
    content: "\e917";
  }
  .icon-wc:before {
    content: "\e919";
  }
  .icon-bxs-select-arrows:before {
    content: "\e91a";
  }
  .icon-garage:before {
    content: "\e91b";
  }
  .icon-bolt:before {
    content: "\e91c";
  }
  .icon-lock:before {
    content: "\e91d";
  }
  .icon-message:before {
    content: "\e91e";
  }
  .icon-distance:before {
    content: "\e920";
  }
  .icon-handicapped:before {
    content: "\e921";
  }
  .icon-hours:before {
    content: "\e922";
  }
  .icon-electric:before {
    content: "\e923";
  }
  .icon-door-lock:before {
    content: "\e924";
  }
  .icon-heart-line:before {
    content: "\e925";
  }
  .icon-heart:before {
    content: "\e927";
  }
  .icon-full-screen:before {
    content: "\e928";
  }
  .icon-location:before {
    content: "\e929";
  }
  .icon-list:before {
    content: "\e92b";
  }
  .icon-history:before {
    content: "\e92c";
  }
  .icon-hand:before {
    content: "\e92d";
  }
  .icon-parking:before {
    content: "\e92e";
  }
  .icon-down:before {
    content: "\e92f";
  }
  .icon-all:before {
    content: "\e930";
  }
  /***************** icons end **************/
#root,.App,.height100{
    height: 100%;
}
.ant-layout-content{
    background: transparent !important;
}

.shadowBox{
    background: #FFFFFF;
box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.07);
border-radius: 4px;
padding: 30px 50px;
margin-top: 30px; 
max-width: 670px;
}
.user-row{
    border-bottom: #EAEDF3 solid 1px;
    padding-bottom: 15px;
 
}
.user-row.last-one{
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.user-row h3{
font-size: 16px;
line-height: 19px;  
color: #444444;
margin: 0 0 15px;
display: flex;
width: 100%;
align-items: center;
}
.user-row button{
    margin-left: auto;
}
.user-row p{
    font-size: 16px;
line-height: 1.5;  
color: #666666;
margin-bottom: 8px;
}
.page-title{
    font-size: 32px;
line-height: 1.3;  
color: #444444;
margin: 0 0 15px;
}
.spotParkingDescription p{
    font-size: 16px;
line-height: 1.5;  
color: #666666;
}
.spotParkinGrid{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
}
.spotParkinGrid span{
    background: #ccc;
    border-radius: 4px;
    width: 178px;
    height: 138px;
    margin-bottom: 18px;
}
.spotParkinGrid span img{
    height: 100%;
    width: 100%;
    margin: 0;
    border-radius: 4px;
}
.spotAmenities ul,.spotAmenities li{
 list-style: none;
 margin: 0;
 padding: 0;
 display: flex;
 flex-wrap: wrap;
}
.spotAmenities li{
    font-size: 16px;
line-height: 23px;  
color: #444444;
    align-items: center;
    max-width: 50%;
    width: 100%;
    padding: 0 10px 0px 0;
    margin: 15px 0;
}
.spotAmenities li .parkao-icon{
    margin-right: 14px;
    font-size: 24px;
    color: #666;
}
.AmentitiesIcon{
    width: 24px;
    height: 24px;
    /* background: #ccc; */
    margin-right: 14px;
    font-size: 24px;
    color: #666;
}
.spotOwner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    padding-left: 102px; 
    min-height: 78px;
    align-items: center;
}
.spotOwnerWrap h2{
    font-size: 16px;
line-height: 19px;  
color: #444444;
margin-bottom: 15px;
}
.spotOwner .ownerProfileImg{
    height: 78px;
    width: 78px;
    border-radius: 50%;
    top: auto;
    left: 0;
    background: #ccc;
    position: absolute;
}
.spotOwnerWrap .spotOwner h3{
    margin-bottom: 8px;
    font-size: 16px;
line-height: 20px;  
color: #444444;
}
.profileBtn{
    outline: none;
    border: 1.5px solid #D9D8D8;
box-sizing: border-box;
border-radius: 4px;
font-size: 14px;
line-height: 22px; 
text-transform: capitalize; 
color: #000000;
padding: 9px 23px;
height: auto;
transition: all .2s ease-in-out;
}
.profileBtn:hover,.profileBtn:focus{
    background: #006BD3;
    color: #fff;
}
.spotOwnerWrap .spotOwner p{
    font-size: 14px;
    line-height: 17px;  
    color: #444444;
    margin: 0;
}
.reporterInfo{
    margin: 30px 0 0;
}
.reporterInfo h3 a{
    margin-left: auto;
    font-size: 16px;
line-height: 19px; 
text-decoration: none;
color: #006BD3;
}
.reporterInfo input[type=text]{
    border: 1px solid #DADADA;
box-sizing: border-box;
border-radius: 3px;
font-size: 16px;
line-height: 25px;
color: #666666;
padding: 12px 20px;
}
.reporterInfo .sbmtBtn{
    background: #006BD3;
border-radius: 4px;
color: #fff;
font-weight: 600;
font-size: 14px;
line-height: 18px;
padding: 10px 25px;
height: auto;
margin: 15px 0;
}
.bookingCard{
display: flex;
align-items: center; 
background: #006BD3;
box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
border-radius: 8px;
padding: 20px 40px;
color: #fff;
} 
.booking-title,.booking-date,.booking-time{
    width: 100%;
    display: inline-block;
    font-size: 16px;
    line-height: 23px;
}
.booking-title{
    text-transform: uppercase;
    color: #FFB44C;
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 15px;
}
.bookingCard .col{
    height: 100%;
}
.mid-col{
    text-align: center;
}
.last-col{
    margin-left: auto;
    max-width: 100px;
}
.mid-col .booking-title{
    margin-bottom: 5px;
}
.big-price{
    font-size: 24px;
line-height: 29px;  
color: #006BD3;
font-weight: 700;
}
.tableHeaderText {
        display: inline-flex;
        width: 100%;
        padding-top: 2em;
        padding-bottom: 2em;
}
.tableMainHead {
    width: 80%;
}
.titleTextStyle {
    font-size: 22px;
    font-weight: bolder;
}
/***** user-profile ************/
.ant-layout-header {
    height: 72px;
    padding: 0 30px;
    align-items: center;
    display: flex;
}
.ant-layout-header .slogan,
.ant-layout-header .userName{
    color: #FFFFFF;
    font-size: 18.2712px;
    margin: 0 0 0 15px;
    width: auto;
}
.ant-layout-header  .user-box{
    margin-left: auto;
    height: 100%; 
    align-items: center;
    display: flex;
}
.ant-layout-header .userName{
    font-size: 16px;
}
 
.container{
   max-width: 1170px; 
   margin: 0 auto; 
   padding: 30px 15px; 
   width: 100%;
}
.shadow-box{
background: #FFFFFF;
box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.07);
border-radius: 4px;
padding: 40px 36px;
}
.user-sidebar hr{
    border: none;
    border-top: #B2B2B2 solid 1px;
    opacity: .5;
    margin: 40px 0;
}
.user-sidebar ul,
.user-sidebar li{
 list-style: none;
 margin: 0;
 padding: 0;
}
.imageWrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.imageWrap .userName{
    width: 100%;
    text-align: center;
    font-size: 32px;   
color: #444444;
margin: 20px 0 18px;
}
.imageWrap .imageStyle{
    width:190px;
    height: 190px;
    border-radius: 50%;
}
.imageWrap .user-reviews{
    width: 100%;
    text-align: center;
    color: #006BD3;
    font-size: 16px;
}
.user-sidebar li{
    position: relative;
    padding: 10px 0;
    font-size: 16px; 
color: #666666;
}
.user-sidebar li i{
    margin-right: 10px;
}
.user-sidebar h3{
    font-size: 16px; 
color: #444444;
}
.lineRatings{
    padding: 0 0 30px;
}
.lineRatings .single-line{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 16px;  
    color: #444444;
    padding: 3px 0;
}
.single-line .rating-line{
    width: 311px;
    margin: auto 30px;
    position: relative; 
}
.single-line .rating-line::before{
    position: absolute;
    content: "";
    left: 0;
    top: 0; 
    width: 311px;
    height: 5px;
    background: #E0E0E0;
    border-radius: 10px;
}
.single-line .rateLine{
    max-width: 311px;
    background: #006BD3;
    border-radius: 10px;
    height: 5px;
    line-height: 5px;
    position: absolute;
    left: 0;
    border-radius: 10px;
    top: 0;
    z-index: 1;
}
.single-line .rating-title{
    width: 70px;
}
.profile-content h2{
    font-size: 16px;  
    color: #444444;
    margin: 10px 0;
}
.user-profile-reviews{
    list-style: none;
    margin: 20px 0 0;
    padding: 0;
}
.user-profile-reviews li{
    list-style: none;
    margin: 0 0 30px;
    padding: 5px 0 10px 75px;
    position: relative; 
}
.user-profile-reviews li::before{
    position: absolute;
    content: "";
    height: 1px;
    width: calc(100% - 75px);
    left: 75px;
    bottom: 0;
    background: #B2B2B2;
    opacity: 0.5;
}
.user-profile-reviews li h3{
    display: flex;
    align-items: center;
    font-size: 16px; 
color: #000000;
width: 100%;
font-weight: 400;
margin: 0 0 5px;
}
.user-profile-reviews .review-date{
    font-size: 14px; 
color: #444444;
display: block;
margin-bottom: 20px;
}
.user-profile-reviews p{
    font-size: 16px; 
color: #666666;
margin: 0 0 15px;
line-height: 1.6;
}
.user-profile-reviews li .author-image,.user-profile-reviews li .author-image img{
    height: 55px;
    width: 55px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    background: #efefef;
}
.profile-stars{
    margin-left: 15px;
}
.loadMore{
font-size: 14px; 
text-transform: capitalize; 
color: #000000;
padding: 10px 20px;
border: 1.5px solid #D9D8D8;
box-sizing: border-box;
border-radius: 4px;
height: auto;
outline: none;
transition: all ease-in-out .2s;
box-shadow: none;
border: none;
cursor: pointer;
}
.loadMore:hover,.loadMore:focus{
    background: #006BD3;
    color: #fff;
    outline: none; 
box-shadow: none;
}
.text-center{
    text-align: center;
}
.fullDivider{
    margin: 40px -36px;
    background: #B2B2B2;
opacity: 0.5;
}

.similarParking{
    position: relative; 
    overflow: hidden;
    margin: 0 -36px;
    padding: 0 0 0 36px; 
    display: flex;
    flex-wrap: nowrap;
}
.similar-places-title{
    margin: 20px 0 30px;
    width: 100%;
}
.similar-place{
background: #FFFFFF;
border: 1px solid rgba(217, 216, 216, 0.5);
box-sizing: border-box;
box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
border-radius: 4px;
padding: 0 20px 20px;
margin-right: 25px;
width: 100%;
max-width: 280px;
min-width: 260px;
}
.similar-place .place-image{
    height: 207px; 
    background: #666;
    display: block;
    margin: 0 -20px;
    position: relative;
    border-radius: 4px 4px 0 0;
}
.similar-place .place-image img{
    border-radius: 4px 4px 0 0;
    height: 100%; 
    position: relative;
}
.similar-place .place-image .heart-icon{
    position: absolute;
    top: 30px; right: 30px;
}
.similar-place .place-title{
    font-size: 24px; 
    color: #444444;
    margin: 15px 0 5px;
    font-weight: 400;
}
.similar-place .place-ratings{
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    color: #444;
}
.similar-place .place-ratings span{
    margin-left: 15px;
}
.similar-place .distance{
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 12px;
    color: #444;
    opacity: 0.64;
    padding: 5px 0 0;
}
.similar-place ul,.similar-place ul li{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.similar-place ul{
    padding: 8px 0 5px;
}
.similar-place ul li{
    width: 50%;
    align-items: center;
    color: #444444;
opacity: 0.88;
font-size: 12px;
padding: 2px 0;
}
.similar-place ul li img{
    margin-right: 5px;
}
.similar-place .btn{
    background: #006BD3; 
border: 0.8px solid #006BD3;
box-sizing: border-box;
border-radius: 4px;
color: #fff;
margin: 10px 10px 0 0;
outline: none;
border: none;
box-shadow: none;
padding: 6px 15px;
cursor: pointer;
transition: all ease-in-out .2s;
}
.similar-place .btn:hover{
    opacity: .8;
}
.similar-place .btn.btn-orange{
    background: #FC972E;
}
.similar-place .btn img{
    margin-right: 5px;
}
.similar-place .btn strong{
    font-size: 16px;
}
.noReviewsStyle {
  padding-top: 8em;
  padding-bottom: 6em;
  text-align: center;
  color: lightgray;
}
ul.price-listing, ul.pricing {
  padding: 0;
  margin: 0;
}
ul.price-listing li, ul.pricing li {
  display: inline-block;
  width: 20%;
}
ul.pricing li h4 {
  font-size: 16px;
  line-height: 23px;
  color:#444444;
  font-weight: normal;
}
.user-box .ant-dropdown-link.ant-dropdown-trigger {
  line-height: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.user-box .anticon.anticon-down {
  margin-left: 10px;
}
.user-box .anticon svg {
  display: inline-block;
  color:#fff;
}
.fr-box.fr-basic.fr-top .fr-wrapper {
  border-top: 0;
  height: 100%;
}
.faq-wrap {
  background:#FFFFFF;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  padding: 30px 50px;
  margin-top: 30px;
  max-width: 1140px;
  margin: 0 auto 40px;
}
.faq-list {
  padding-top: 20px;
  padding-bottom: 10px;
}
.faq-list button.ant-btn {
  margin-top: 0px;
}
.faq-list p {
  font-size: 16px;
  line-height: 1.5;
  color: #666666;
}
.faq-list h3 {
  font-size: 17px;
  line-height: 1.5;
}